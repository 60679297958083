import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';

import { RadioGroup } from '../../components/RadioGroup';

import { useStoreState } from 'pullstate';
import { GameplayStore } from '../../stores/gameplayStore';
import { RollStore } from '../../stores/rollStore';

import { capitalize } from '../../functions/utils';

function updateLevel(levelType, levelValue) {
  RollStore.update((s) => {
    s[levelType] = levelValue?.toLowerCase();
  });
}

function Level(props) {
  const levelType = props.name;
  const levels = useStoreState(GameplayStore, (s) => s[levelType]);
  const selectedLevel = useStoreState(RollStore, (s) => s[levelType]);

  var options = {};
  levels.order.map(
    (levelName) =>
      (options[levelName] = {
        text: capitalize(levelName),
        description: levels.levels[levelName].description,
        colorScheme: levels.levels[levelName].rating,
      })
  );

  return (
    <Flex align="center">
      <Heading size="md" flex={1} m={3}>
        {capitalize(levelType)}
      </Heading>
      <RadioGroup
        name={levelType}
        options={options}
        selected={selectedLevel}
        onChange={(levelName) => updateLevel(levelType, levelName)}
      />
    </Flex>
  );
}

export function Levels() {
  return (
    <Box>
      <Level name="position" />
      <Level name="effect" />
    </Box>
  );
}
