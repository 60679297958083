import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  ButtonGroup,
  useId,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';

import { InfoPopoverButton, PopoverSpan } from '../components/InfoPopover';

import { capitalize } from '../functions/utils';

const defaultColor = 'gray';

function RadioButton(props) {
  const { value, description, colorScheme, onClick, ...rest } = props;
  const text = props.text || capitalize(value);
  const id = useId(props.id);
  const { getInputProps, getCheckboxProps } = useRadio({ id, ...props });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const buttonProps = {
    'aria-label': value,
    as: 'label',
    htmlFor: input.id,
    _checked: { bg: (colorScheme || defaultColor) + '.500' },
    _hover: { bg: (colorScheme || defaultColor) + '.400' },
  };

  return (
    <>
      {description ? (
        <InfoPopoverButton
          {...checkbox}
          {...buttonProps}
          source={description}
          onClick={() => onClick(value)}
          {...rest}
        >
          <Box>
            <PopoverSpan>{text}</PopoverSpan>
          </Box>
        </InfoPopoverButton>
      ) : (
        <Button
          {...checkbox}
          {...buttonProps}
          onClick={() => onClick(value)}
          {...rest}
        >
          {text}
        </Button>
      )}
      <input {...input} />
    </>
  );
}

export function RadioGroup({
  name,
  options,
  selected,
  onChange,
  isDetached,
  isToggleable,
  ...rest
}) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    value: selected,
  });

  function handleClick(value) {
    if (isToggleable && selected === value) {
      onChange(undefined);
    } else {
      onChange(value);
    }
  }

  const group = getRootProps();

  return (
    <ButtonGroup isAttached={!isDetached} {...group}>
      {Object.entries(options).map(([option, data]) => (
        <RadioButton
          {...getRadioProps({ value: option })}
          key={option}
          value={option}
          text={data.text ?? capitalize(option)}
          description={data.description}
          colorScheme={data.colorScheme}
          children={option}
          onClick={handleClick}
          {...rest}
        />
      ))}
    </ButtonGroup>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
  selected: PropTypes.string,
  //onChange: PropTypes.func, // breaks using onChange when uncommented?
  isDetached: PropTypes.any,
  isToggleable: PropTypes.any,
};

RadioGroup.defaultProps = {
  selected: '',
  isDetached: false,
  isToggleable: true,
};
