import React from 'react';
import { Button, Flex, Input } from '@chakra-ui/react';

import { resistRoll } from '../../functions/rolls';

import { useStoreState } from 'pullstate';
import { RollStore, resetRollStore } from '../../stores/rollStore';

function roll() {
  resistRoll();
  resetRollStore();
}

export function RollButton() {
  const attribute = useStoreState(RollStore, (s) => s.attribute);
  const caption = useStoreState(RollStore, (s) => s.caption);

  function handleSubmit(e) {
    e.preventDefault();
    if (!attribute) {
      return;
    }
    roll();
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Flex justify="center">
        <Input
          m={1}
          type="text"
          variant="filled"
          placeholder="Caption roll"
          rounded="lg"
          value={caption}
          onChange={(e) => {
            RollStore.update((s) => {
              s.caption = e.target.value;
            });
          }}
        />
      </Flex>
      <Flex justify="center">
        <Button type="submit" isDisabled={!attribute} colorScheme="teal">
          Roll
        </Button>
      </Flex>
    </form>
  );
}
