import { extendTheme } from '@chakra-ui/react';

const accent = '#e75b06';

const low = {
  50: '#fff9db',
  100: '#ffe7af',
  200: '#fdd280',
  300: '#fabb50',
  400: '#f9a01f',
  500: '#e08006',
  600: '#ae6e02',
  700: '#7d5700',
  800: '#4d3a00',
  900: '#1e1400',
};

const medium = {
  50: '#fff3dd',
  100: '#ffd9af',
  200: '#febc81',
  300: '#fb9c50',
  400: '#f9791f',
  500: '#e05806',
  600: '#af5003',
  700: '#7d4100',
  800: '#4d2b00',
  900: '#200f00',
};

const high = {
  50: '#fff0de',
  100: '#ffd2b0',
  200: '#feb181',
  300: '#fb8b50',
  400: '#f9631f',
  500: '#e04306',
  600: '#af3f04',
  700: '#7d3401',
  800: '#4d2300',
  900: '#200c00',
};

const myColors = {
  accent: accent,
  high: high,
  medium: medium,
  low: low,
};

const Heading = {
  baseStyle: {
    fontFamily: `"Patua One", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    fontWeight: 'normal',
  },
};

const Button = {
  baseStyle: {
    fontSize: '.98rem',
  },
};

export const customTheme = extendTheme({
  colors: {
    ...myColors,
  },
  fonts: {
    body: `"Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  components: {
    Heading,
    Button,
  },
});
