import React from 'react';
import { Stack } from '@chakra-ui/react';

import { Panel } from '../../components/Panel';
import { ActionSelect } from './ActionSelect';
import { Levels } from './Levels';
import { Bonuses } from './Bonus';
import { AdditionalOptions } from '../../components/AdditionalOptions';
import { ActionRollButton } from './ActionRollButton';

export function ActionRollTab() {
  return (
    <Stack direction="column">
      <Panel>
        <ActionSelect />
      </Panel>
      <Panel>
        <Levels />
      </Panel>
      <Panel>
        <Bonuses />
      </Panel>
      <Panel>
        <AdditionalOptions />
      </Panel>
      <Panel>
        <ActionRollButton />
      </Panel>
    </Stack>
  );
}
