/* DO NOT COMMIT THIS FILE WITH CONFIG DATA */

const databaseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://fitd-roller.firebaseio.com'
    : 'https://cloud-games-c92f6.firebaseio.com';

export const firebaseConfig = {
  apiKey: 'AIzaSyCNekaCTiC-Rpsgj78398jKCQ1vsGGRdkc',
  authDomain: 'cloud-games-c92f6.firebaseapp.com',
  databaseURL: databaseURL,
  projectId: 'cloud-games-c92f6',
  storageBucket: 'cloud-games-c92f6.appspot.com',
  messagingSenderId: '63046152121',
  appId: '1:63046152121:web:33211307074c0b45c75068',
  measurementId: 'G-S33TL82FH6',
};
