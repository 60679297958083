import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from '../../functions/utils';

export function SessionNoteMessageContent({ characterName, message }) {
  if (!message) {
    return null;
  }
  if (!characterName) {
    message = capitalize(message);
  }
  return (
    <>
      {characterName && (
        <>
          <span>{characterName}</span> :
        </>
      )}{' '}
      {message}
    </>
  );
}

SessionNoteMessageContent.propTypes = {
  characterName: PropTypes.string,
  message: PropTypes.string,
};
