import { Store } from 'pullstate';

function calculateBonus(bonuses) {
  return Object.values(bonuses)
    .map((b) => (b ? 1 : 0))
    .reduce((a, b) => a + b, 0);
}

function getInitialRollState() {
  var rollState = {
    position: null,
    effect: null,
    bonuses: {
      assist: false,
      push: false,
    },
    bonus: 0,
    action: null,
    attribute: null,
    activity: null,
    fortune: 1,
    advantage: 0,
    disadvantage: 0,
    caption: '',
  };
  rollState.bonus = calculateBonus(rollState.bonuses);
  return rollState;
}

export const RollStore = new Store(getInitialRollState());

// When bonuses changes, recalculate bonus
RollStore.createReaction(
  (s) => s.bonuses,
  (bonuses, draft) => {
    draft.bonus = calculateBonus(bonuses);
  }
);

// Fortune, advantage, and disadvantage cannot go below 0
RollStore.createReaction(
  (s) => ({
    fortune: s.fortune,
    advantage: s.advantage,
    disadvantage: s.disadvantage,
  }),
  (watched, draft) => {
    draft.fortune = Math.max(watched.fortune, 0);
    draft.advantage = Math.max(watched.advantage, 0);
    draft.disadvantage = Math.max(watched.disadvantage, 0);
  }
);

export function resetRollStore() {
  RollStore.update((s) => {
    Object.assign(s, RollStore.initialState);
  });
}
