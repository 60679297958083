import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Spacer,
} from '@chakra-ui/react';

import { StatSlider } from '../../components/CheckboxSlider';
import { InfoPopover, PopoverSpan } from '../../components/InfoPopover';
import { SpecialAbilitiesButton } from './SpecialAbilities';

import { capitalize } from '../../functions/utils';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';
import { GameplayStore } from '../../stores/gameplayStore';

export const xpColor = 'blue';

function SliderHeading({
  label,
  value,
  onChange,
  description,
  rating,
  min,
  max,
  colorScheme,
  width,
}) {
  var heading = (
    <Box>
      <Heading size="sm" d="inline" mr={2}>
        {label}
      </Heading>
      {rating && <span>{rating}</span>}
    </Box>
  );
  if (description) {
    heading = (
      <InfoPopover source={description}>
        <Box>
          <Heading size="sm" d="inline" mr={2}>
            <PopoverSpan>{label}</PopoverSpan>
          </Heading>
          <span>{rating}</span>
        </Box>
      </InfoPopover>
    );
  }
  return (
    <Flex align="center" justify="center" w={width || '100%'}>
      <Flex>{heading}</Flex>
      <Spacer />
      <Box marginLeft={3}>
        <StatSlider
          value={value}
          onChange={onChange}
          colorScheme={colorScheme}
          min={min}
          max={max}
        />
      </Box>
    </Flex>
  );
}

export function UnusedXP() {
  const unusedXP = useStoreState(CharacterStore, (s) => s?.xp?.unused || 0);

  function handleChange(value) {
    CharacterStore.update((s) => {
      s.xp ?? (s.xp = {});
      s.xp.unused = value;
    });
  }

  return (
    <Box mx={2} my={0}>
      <SliderHeading
        label="Unused XP"
        value={unusedXP}
        onChange={handleChange}
        colorScheme={xpColor}
        min={0}
        max={9}
      />
    </Box>
  );
}

export function PlaybookAccordionItem() {
  const playbookXP = useStoreState(CharacterStore, (s) => s?.xp?.playbook || 0);

  function handleChange(value) {
    CharacterStore.update((s) => {
      s.xp ?? (s.xp = {});
      s.xp.playbook = value;
    });
  }

  return (
    <AccordionItem border={0}>
      <HStack mx={2}>
        <SliderHeading
          label={'Playbook'}
          value={playbookXP}
          onChange={handleChange}
          colorScheme={xpColor}
          min={0}
          max={8}
        />
        <AccordionButton p={0} w="fit-content">
          <AccordionIcon />
        </AccordionButton>
      </HStack>

      <AccordionPanel pb={1}>
        <Center w="100%" pb={2} pt={1}>
          <SpecialAbilitiesButton />
        </Center>
      </AccordionPanel>
    </AccordionItem>
  );
}

function ActionSlider(props) {
  const { value: action } = props;
  const value = useStoreState(CharacterStore, (s) => s?.actions?.[action]);
  const actions = useStoreState(GameplayStore, (s) => s?.actions);

  function handleChange(value) {
    CharacterStore.update((s) => {
      s.actions ?? (s.actions = {});
      s.actions[action] = value;
    });
  }

  return (
    <Flex mx={1} align="baseline">
      <Box marginRight={3}>
        <StatSlider
          flex="1"
          value={value}
          onChange={handleChange}
          min={0}
          max={4}
        />
      </Box>
      <Flex mx={1}>
        <InfoPopover source={actions[action].description}>
          <span m={1}>
            <PopoverSpan>{capitalize(action)}</PopoverSpan>
          </span>
        </InfoPopover>
      </Flex>
    </Flex>
  );
}

function AttributeAccordionHeaderTest(props) {
  const { value } = props;
  const { attributeRating, attributeXP } = useStoreState(
    CharacterStore,
    (s) => ({
      attributeRating: s?.attributes?.[value] || 0,
      attributeXP: s?.xp?.[value] || 0,
    })
  );
  const attribute = useStoreState(GameplayStore, (s) => s?.attributes?.[value]);

  function handleChange(xp) {
    CharacterStore.update((s) => {
      s.xp ?? (s.xp = {});
      s.xp[value] = xp;
    });
  }

  return (
    <HStack mx={2}>
      <SliderHeading
        label={capitalize(value)}
        value={attributeXP}
        onChange={handleChange}
        description={attribute?.description}
        rating={attributeRating}
        colorScheme={xpColor}
        min={0}
        max={6}
      />
      <AccordionButton p={0} w="fit-content">
        <AccordionIcon />
      </AccordionButton>
    </HStack>
  );
}

function AttributeAccordionItemTest() {
  return (
    <AccordionItem border={0}>
      <AttributeAccordionHeaderTest value="insight" />
      <AccordionPanel pb={1}>
        <Box ml={4}>whatever</Box>
      </AccordionPanel>
    </AccordionItem>
  );
}

function AttributeAccordionHeader(props) {
  const { value } = props;
  const { attributeRating, attributeXP } = useStoreState(
    CharacterStore,
    (s) => ({
      attributeRating: s?.attributes?.[value] || 0,
      attributeXP: s?.xp?.[value] || 0,
    })
  );
  const attribute = useStoreState(GameplayStore, (s) => s?.attributes?.[value]);

  function handleChange(xp) {
    CharacterStore.update((s) => {
      s.xp ?? (s.xp = {});
      s.xp[value] = xp;
    });
  }

  return (
    <HStack mx={2}>
      <InfoPopover source={attribute?.description}>
        <Box>
          <Heading size="sm" d="inline" mr={2}>
            <PopoverSpan>{capitalize(value)}</PopoverSpan>
          </Heading>
          <span>{attributeRating}</span>
        </Box>
      </InfoPopover>
      <Spacer />
      <StatSlider
        flex="1"
        value={attributeXP}
        onChange={handleChange}
        colorScheme={xpColor}
        min={0}
        max={6}
      />
      <AccordionButton p={0} w="fit-content">
        <AccordionIcon />
      </AccordionButton>
    </HStack>
  );
}

export function AttributeAccordionItem(props) {
  const { value: attributeName, actions } = props;

  return (
    <AccordionItem border={0}>
      <AttributeAccordionHeader value={attributeName} />
      <AccordionPanel pb={1}>
        <Box ml={4}>
          {actions.map((actionName) => (
            <ActionSlider key={actionName} value={actionName} />
          ))}
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
}

function AttributeAccordionItems() {
  const attributes = useStoreState(GameplayStore, (s) => s?.attributes);

  if (!attributes) {
    return null;
  }

  return (
    <>
      {Object.keys(attributes).map((attributeName) => (
        <AttributeAccordionItem
          key={attributeName}
          value={attributeName}
          actions={attributes[attributeName].actions}
        />
      ))}
    </>
  );
}

export function Stats() {
  return (
    <>
      <UnusedXP />
      <Accordion allowToggle allowMultiple py={2} pl={1}>
        <PlaybookAccordionItem />
        <AttributeAccordionItems />
      </Accordion>
    </>
  );
}
