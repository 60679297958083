import React from 'react';
import PropTypes from 'prop-types';

import { formatDice, formatRoll } from './utils';
import { InfoPopover, PopoverSpan } from '../InfoPopover';
import { capitalize } from '../../functions/utils';

import { GameplayStore } from '../../stores/gameplayStore';

const outcomeNames = {
  crit: 'Critical Success',
  hit: 'Success',
  mixed: 'Mixed Success',
  miss: 'Miss',
};

function renderOutcome(position, outcome) {
  position = position || 'risky';
  outcome = outcome || 'mixed';
  const outcomeName = outcomeNames[outcome];
  const outcomeText = `${capitalize(position)} ${outcomeName}`;
  const info =
    GameplayStore.currentState?.position?.levels[position]?.results[outcome];
  if (!info) {
    return outcomeText;
  }

  return (
    <InfoPopover source={info}>
      <span m={1}>
        <PopoverSpan>{outcomeText}</PopoverSpan>
      </span>
    </InfoPopover>
  );
}

function renderEffect(effect) {
  effect = effect || 'standard';
  const effectText = `${capitalize(effect)} Effect`;
  const info = GameplayStore.currentState?.effect?.levels[effect]?.description;
  if (!info) {
    return effectText;
  }

  return (
    <InfoPopover source={info}>
      <span>
        <PopoverSpan>{effectText}</PopoverSpan>
      </span>
    </InfoPopover>
  );
}

export function ActionRollMessageContent(props) {
  const {
    characterName,
    actionName,
    actionRating,
    bonus,
    advantage,
    disadvantage,
    position,
    effect,
    outcome,
    caption,
    roll,
  } = props;

  const rollText = ` ${formatDice(
    actionRating,
    bonus + advantage,
    disadvantage
  )}: `;

  return (
    <>
      <div>
        <span>{characterName}</span>
        {` rolled `}
        <span>{actionName?.toUpperCase()}</span>
        {rollText}
      </div>
      <div>
        {renderOutcome(position, outcome)} with {renderEffect(effect)}
      </div>
      <div>
        <p>{caption}</p>
        {formatRoll(roll)}
      </div>
    </>
  );
}

ActionRollMessageContent.propTypes = {
  characterName: PropTypes.string,
  actionName: PropTypes.string,
  actionRating: PropTypes.number,
  bonus: PropTypes.number,
  advantage: PropTypes.number,
  disadvantage: PropTypes.number,
  position: PropTypes.string,
  effect: PropTypes.string,
  outcome: PropTypes.oneOf(['crit', 'hit', 'mixed', 'miss']),
  caption: PropTypes.string,
  roll: PropTypes.string,
};

ActionRollMessageContent.defaultProps = {
  position: 'risky',
  effect: 'standard',
  outcome: 'mixed',
};
