import React from 'react';
import { Stack } from '@chakra-ui/react';

import { Panel } from '../../components/Panel';
import { AttributeSelect } from './AttributeSelect';
import { AdditionalOptions } from '../../components/AdditionalOptions';
import { RollButton } from './ResistRollButton';

export function ResistRollTab() {
  return (
    <Stack direction="column">
      <Panel>
        <AttributeSelect />
      </Panel>
      <Panel>
        <AdditionalOptions />
      </Panel>
      <Panel>
        <RollButton />
      </Panel>
    </Stack>
  );
}
