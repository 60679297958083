import React from 'react';
import { Box, Heading, SimpleGrid, VStack } from '@chakra-ui/react';

import { InfoPopoverButton, PopoverSpan } from '../../components/InfoPopover';
import { Pips } from '../../components/Pips';
import { capitalize } from '../../functions/utils';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';
import { GameplayStore } from '../../stores/gameplayStore';
import { RollStore } from '../../stores/rollStore';

function ActionButton(props) {
  const { value, isChecked, isDisabled, ...rest } = props;
  const attributes = useStoreState(CharacterStore, (s) => s?.actions);
  const gameplay = useStoreState(GameplayStore);
  const isLoaded = gameplay !== undefined && attributes !== undefined;
  const actionName = value;
  const pips = attributes && <Pips value={attributes[actionName]} />;

  return (
    <>
      {isLoaded ? (
        <InfoPopoverButton
          value={value}
          w="130px"
          m="3px"
          colorScheme={isChecked ? 'medium' : 'gray'}
          _checked={{ bg: 'medium.500' }}
          _hover={{ bg: 'medium.400' }}
          aria-checked={isChecked}
          isDisabled={isDisabled}
          role="radio"
          onClick={() => {
            RollStore.update((s) => {
              s.action = value;
            });
          }}
          source={gameplay.actions[actionName]['short']}
          {...rest}
        >
          <Box mt="1">
            <span className="action">
              <PopoverSpan>{capitalize(actionName)}</PopoverSpan>
            </span>
            <span className="actionRating">
              &nbsp;({attributes[actionName] || 0}d)
              <br />
              {pips}
            </span>
          </Box>
        </InfoPopoverButton>
      ) : (
        'Loading'
      )}
    </>
  );
}

export function ActionSelect() {
  const gameplay = useStoreState(GameplayStore);
  const isLoaded = gameplay !== undefined;
  const action = useStoreState(RollStore, (s) => s.action);

  return (
    <Box>
      <Heading size="md" flex={1} m={3}>
        Action
      </Heading>
      {isLoaded ? (
        <SimpleGrid columns={3} spacing={3}>
          {Object.keys(gameplay.attributes).map((attributeName) => (
            <VStack key={attributeName}>
              <Heading size="sm">{capitalize(attributeName)}</Heading>
              {gameplay.attributes[attributeName].actions.map((actionName) => {
                return (
                  <ActionButton
                    key={actionName}
                    value={actionName}
                    isChecked={actionName === action}
                  />
                );
              })}
            </VStack>
          ))}
        </SimpleGrid>
      ) : (
        'Loading'
      )}
    </Box>
  );
}
