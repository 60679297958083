import { Store } from 'pullstate';
import { db } from './firebase';

export const GameplayStore = new Store();

export function getGameplayStore(channelName) {
  if (!channelName) {
    return;
  }

  let gameKey = '';
  const srd = require('../gamedata/srd.json');
  // get system key for channel
  db.ref(channelName)
    .child('systemKey')
    .once('value')
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        gameKey = snapshot.val();
        console.log(`Loading game: ${gameKey}`);
        const gameplayExtension = await import(
          `../gamedata/games/${gameKey}.json`
        );

        return { ...srd, ...gameplayExtension };
      }
      return srd;
    })
    .then((gameplay) => {
      GameplayStore.replace(gameplay);
    });
}
