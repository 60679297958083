import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@chakra-ui/react';
var debounce = require('lodash.debounce');

export function ControlledInput({ value, onChange, debounceMs, ...rest }) {
  const [tempValue, setTempValue] = useState(value);

  const debounceOnChange = useMemo(
    () =>
      debounce((values) => onChange(values), debounceMs, {
        leading: false,
      }),
    [onChange, debounceMs]
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    setTempValue(newValue);
    debounceOnChange(newValue);
  };

  return <Input value={tempValue} onChange={handleChange} {...rest} />;
}

ControlledInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export function ControlledInputArray({
  values,
  max,
  fill,
  onChange,
  debounceMs,
  ...rest
}) {
  const [tempValues, setTempValues] = useState(values);

  const debounceOnChange = useMemo(
    () =>
      debounce(
        (values) => {
          onChange(values);
        },
        debounceMs,
        {
          leading: false,
        }
      ),
    [onChange, debounceMs]
  );

  const handleChange = (value, i) => {
    var newValues = values;
    if (i < tempValues.length) {
      newValues = tempValues.map((item, index) => (index === i ? value : item));
    } else {
      newValues = [
        ...tempValues,
        ...Array(i - tempValues.length).fill(fill),
        value,
      ];
    }

    setTempValues(newValues);
    debounceOnChange(newValues);
  };

  // if array is overfull, reduce empty indices
  const emptyCount = tempValues.filter((item) => !item || item === fill).length;
  if (tempValues.length > max && emptyCount > 0) {
    const newValues = tempValues.filter((item) => item && item !== fill);
    setTempValues(newValues);
    debounceOnChange(newValues);
  }

  let inputs = [];
  const count = Math.max(values?.length, max) || 0;
  for (let i = 0; i < count; i++) {
    inputs.push(
      <Input
        key={i}
        value={tempValues[i] || ''}
        onChange={(event) => handleChange(event.target.value, i)}
        isInvalid={i >= max}
        {...rest}
      />
    );
  }
  return <>{inputs}</>;
}

ControlledInputArray.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func,
  max: PropTypes.number,
  fill: PropTypes.any,
  debounceMs: PropTypes.number,
};
