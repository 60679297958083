import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from '@chakra-ui/react';

import { LabelledDicePool } from './LabelledDicePool';

export function AdditionalOptions() {
  return (
    <Accordion allowToggle>
      <AccordionItem border={0}>
        <Heading size="sm">
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Additional Options
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel pb={1}>
          <Box>
            <LabelledDicePool
              label={'Add Dice'}
              attr={'advantage'}
              min={0}
              prefix={'+'}
            />
            <LabelledDicePool
              label={'Remove Dice'}
              attr={'disadvantage'}
              min={0}
              prefix={'-'}
            />
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
