import React from 'react';
import { Box, Heading, Stack } from '@chakra-ui/react';

import { LabelledDicePool } from '../../components/LabelledDicePool';
import { Panel } from '../../components/Panel';
import { RollButton } from './FortuneRollButton';

export function FortuneRollTab() {
  return (
    <Stack direction="column">
      <Panel>
        <Heading size="md" flex={1} m={3}>
          Fortune
        </Heading>
        <Box m="15px">
          <LabelledDicePool label={'Pool'} attr={'fortune'} min={0} />
          <LabelledDicePool
            label={'Major Advantages'}
            attr={'advantage'}
            min={0}
            prefix={'+'}
          />
          <LabelledDicePool
            label={'Major Disadvantages'}
            attr={'disadvantage'}
            min={0}
            prefix={'-'}
          />
        </Box>
      </Panel>
      <Panel>
        <RollButton />
      </Panel>
    </Stack>
  );
}
