import React, { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CloseButton,
  Flex,
  Heading,
  Stack,
  VStack,
  HStack,
} from '@chakra-ui/react';

import { CheckboxSlider } from '../../components/CheckboxSlider';
import { ControlledInputArray } from '../../components/ControlledInput';
import { LabelledNumberInput } from '../../components/LabelledDicePool';
import { RadioGroup } from '../../components/RadioGroup';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';
import { GameplayStore } from '../../stores/gameplayStore';

import { capitalize } from '../../functions/utils';

export function Load() {
  const loadData = useStoreState(GameplayStore, (s) => s?.load);
  const load = useStoreState(CharacterStore, (s) => s?.load);

  function updateLoad(key, value) {
    CharacterStore.update((s) => {
      s.load ?? (s.load = {});
      s.load[key] = value ?? null;
    });
  }

  function updateLoadCount(value) {
    updateLoad('level', value);
    setCount(loadData.levels[value]?.count || 0);
  }

  const [count, setCount] = useState(
    loadData && load && load.level ? loadData.levels[load.level].count : 0
  );

  if (!loadData) {
    return null;
  }

  var options = {};
  loadData.order.map(
    (levelName) =>
      (options[levelName] = {
        text: capitalize(levelName),
        description: loadData.levels[levelName].description,
        colorScheme: loadData.levels[levelName].rating,
      })
  );

  return (
    <Box p={2}>
      <Accordion allowToggle pt={2} pl={1}>
        <AccordionItem border={0}>
          <Flex align="center">
            <Heading size="sm" flex={1}>
              Load
            </Heading>
            <RadioGroup
              name="load"
              options={options}
              selected={load?.level}
              onChange={updateLoadCount}
              size="sm"
            />
            <AccordionButton p={0} w="fit-content">
              <AccordionIcon />
            </AccordionButton>
          </Flex>
          <AccordionPanel pb={0} mb={0}>
            <LabelledNumberInput
              label="Extra Load"
              onChange={(value) => updateLoad('extra', value)}
              defaultValue={load?.extra || 0}
              size="xs"
              fontSize="sm"
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {count > 0 && (
        <VStack mt={2}>
          <HStack spacing={3}>
            <CheckboxSlider
              max={count + (load?.extra || 0)}
              value={load?.loadout?.filter((item) => item).length || 0}
              onChange={() => {}}
              cursor="default"
              icon={<></>}
            />
            <CloseButton size="sm" />
          </HStack>
          <Stack spacing={0}>
            <ControlledInputArray
              values={load?.loadout || []}
              max={count + (load?.extra || 0)}
              onChange={(values) => updateLoad('loadout', values)}
              fill=""
              debounceMs={300}
            />
          </Stack>
        </VStack>
      )}
    </Box>
  );
}
