import React from 'react';
import { Stack } from '@chakra-ui/react';

import { AdditionalOptions } from '../../components/AdditionalOptions';
import { Panel } from '../../components/Panel';
import { ActivitySelect } from './ActivitySelect';
import { RollButton } from './DowntimeRollButton';

export function DowntimeRollTab() {
  return (
    <Stack direction="column">
      <Panel>
        <ActivitySelect />
      </Panel>
      <Panel>
        <AdditionalOptions />
      </Panel>
      <Panel>
        <RollButton />
      </Panel>
    </Stack>
  );
}
