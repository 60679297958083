import React, { useState } from 'react';
import { Box, Button, Heading, Input, VStack } from '@chakra-ui/react';

import { UIControls } from '../components/UIControls';
import { License } from '../components/License';

import { setChannelCharacter } from '../functions/utils';

export function Channel(props) {
  const { channel: channelName, character: characterName } = props;
  const [tempChannelName, setTempChannelName] = useState(channelName);
  const [tempCharacterName, setTempCharacterName] = useState(characterName);

  function handleSubmit(e) {
    e.preventDefault();
    setChannelCharacter(tempChannelName, tempCharacterName);
  }

  return (
    <VStack>
      <UIControls />
      <Box _hover={{ color: 'high.400' }}>
        <Heading m="15px">Forged in the Dark Roller</Heading>
      </Box>
      <form onSubmit={handleSubmit}>
        <VStack maxWidth="225px" my="20px">
          <Input
            type="text"
            style={{ textAlign: 'center' }}
            name="channel"
            rounded="lg"
            placeholder="Channel Name"
            value={
              tempChannelName != null ? tempChannelName : channelName || ''
            }
            onChange={(e) => setTempChannelName(e.target.value)}
          />
          <Input
            type="text"
            style={{ textAlign: 'center' }}
            name="character name"
            rounded="lg"
            placeholder="Character Name"
            value={
              tempCharacterName != null
                ? tempCharacterName
                : characterName || ''
            }
            onChange={(e) => setTempCharacterName(e.target.value)}
          />
          <Button type="submit" colorScheme="teal">
            Enter
          </Button>
        </VStack>
      </form>
      <Box maxWidth="50%" mt="15px">
        <License />
      </Box>
    </VStack>
  );
}
