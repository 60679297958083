import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Heading, SimpleGrid } from '@chakra-ui/react';

import { InfoPopoverButton, PopoverSpan } from '../../components/InfoPopover';
import { capitalize } from '../../functions/utils';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';
import { GameplayStore } from '../../stores/gameplayStore';
import { RollStore } from '../../stores/rollStore';

function ActivityButton(props) {
  const { value, isChecked, isDisabled, ...rest } = props;
  const activityName = value;
  const vice = useStoreState(CharacterStore, (s) => s?.vice);
  const downtime = useStoreState(GameplayStore, (s) => s.downtime);

  return (
    <InfoPopoverButton
      value={activityName}
      w="200px"
      m="3px"
      colorScheme={isChecked ? 'medium' : 'gray'}
      _checked={{ bg: 'medium.500' }}
      _hover={{ bg: 'medium.400' }}
      aria-checked={isChecked}
      isDisabled={isDisabled}
      role="radio"
      onClick={() => {
        RollStore.update((s) => {
          s.activity = downtime.activities[activityName].key;
        });
      }}
      source={downtime.activities[activityName].description}
      {...rest}
    >
      <span>
        <PopoverSpan>
          <span className="action">{capitalize(activityName)}</span>
        </PopoverSpan>
        {downtime.activities[activityName].key === 'vice' ? (
          <span className="actionRating">&nbsp;({vice || 0}d)</span>
        ) : (
          ''
        )}
      </span>
    </InfoPopoverButton>
  );
}

export function ActivitySelect() {
  const activity = useStoreState(RollStore, (s) => s.activity);
  const downtime = useStoreState(GameplayStore, (s) => s.downtime);

  return (
    <Box>
      <Heading size="md" flex={1} m={3}>
        Downtime
      </Heading>
      <Box mx={3} mb={3}>
        <ReactMarkdown source={downtime.description} escapeHtml={false} />
      </Box>

      <SimpleGrid columns={2} spacing={3}>
        {Object.keys(downtime.activities).map((activityName) => (
          <div align="center" key={downtime.activities[activityName].key}>
            <ActivityButton
              value={activityName}
              isChecked={downtime.activities[activityName].key === activity}
            />
          </div>
        ))}
      </SimpleGrid>
    </Box>
  );
}
