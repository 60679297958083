import React, { useEffect } from 'react';
import { FaEye, FaEyeSlash, FaMoon, FaSun } from 'react-icons/fa';
import {
  Box,
  HStack,
  IconButton,
  Tooltip,
  useColorMode,
} from '@chakra-ui/react';

import { useStoreState } from 'pullstate';
import { SettingsStore } from '../stores/settingsStore';

/* Address ColorMode issue that is fixed but not yet released -- remove later
https://github.com/chakra-ui/chakra-ui/issues/2275
*/
function ColorModeFix() {
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    if (colorMode === '') {
      toggleColorMode();
    }
  }, [colorMode, toggleColorMode]);

  return null;
}

const showDelay = '700';

function toggleMode(mode) {
  SettingsStore.update((s) => {
    s[mode] = !s[mode];
  });
}

function ModeSwitch(props) {
  const { mode, icon, label, onClick } = props;
  const text = label[mode];
  const SwitchIcon = icon[mode];

  return (
    <Tooltip hasArrow label={text} openDelay={showDelay}>
      <IconButton
        size="md"
        fontSize="lg"
        aria-label={text}
        variant="ghost"
        color="current"
        onClick={onClick}
        icon={SwitchIcon}
        _hover={{ color: 'accent' }}
      />
    </Tooltip>
  );
}

function ColorModeSwitch() {
  ColorModeFix(); // Remove when ColorMode fix is released
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <ModeSwitch
      mode={colorMode}
      icon={{ light: <FaMoon />, dark: <FaSun /> }}
      label={{
        light: 'Switch to dark mode',
        dark: 'Switch to light mode',
      }}
      onClick={toggleColorMode}
    />
  );
}

function ShowInfoSwitch() {
  const showInfoMode = useStoreState(SettingsStore, (s) => s.showInfoMode);

  return (
    <ModeSwitch
      mode={showInfoMode}
      icon={{ true: <FaEyeSlash />, false: <FaEye /> }}
      label={{
        true: 'Hide info on hover',
        false: 'Show info on hover',
      }}
      onClick={() => toggleMode('showInfoMode')}
    />
  );
}

export function UIControls() {
  return (
    <HStack spacing={0}>
      <ShowInfoSwitch />
      <Box width="5px" m={0} />
      <ColorModeSwitch />
    </HStack>
  );
}
