import React from 'react';
import { RollButton } from '../../components/RollButton';

import { actionRoll } from '../../functions/rolls';

import { useStoreState } from 'pullstate';
import { RollStore, resetRollStore } from '../../stores/rollStore';

export function ActionRollButton() {
  const position = useStoreState(RollStore, (s) => s.position);
  const effect = useStoreState(RollStore, (s) => s.effect);
  const action = useStoreState(RollStore, (s) => s.action);

  function roll() {
    actionRoll();
    resetRollStore();
  }

  function isDisabled() {
    return !action || !position || !effect;
  }

  return <RollButton isDisabled={isDisabled()} roll={roll} />;
}
