import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, HStack } from '@chakra-ui/react';

export function StatSlider(props) {
  return <CheckboxSlider icon={<></>} {...props} />;
}

export function CheckboxSlider({
  value,
  onChange,
  min,
  max,
  colorScheme,
  ...rest
}) {
  var lastToggled = null;

  function handleChange(e) {
    lastToggled = parseInt(e.target.value);
    var newValue = lastToggled;
    if (lastToggled === value) {
      newValue = lastToggled - 1;
    }
    onChange(newValue);
  }

  function checkboxes() {
    var checkboxes = [];
    for (var i = min + 1; i < max + 1; i++) {
      var v = value || 0;
      checkboxes.push(
        <Checkbox
          key={i}
          value={i}
          aria-label={i}
          colorScheme={colorScheme || 'gray'}
          isChecked={v >= i}
          onChange={handleChange}
          {...rest}
        />
      );
    }
    return checkboxes;
  }

  return <HStack>{checkboxes()}</HStack>;
}

CheckboxSlider.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  colorScheme: PropTypes.string,
};

CheckboxSlider.defaultProps = {
  value: 0,
  min: 0,
};
