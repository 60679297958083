import React from 'react';
import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { ActionRollTab } from './views/actionRoll/ActionRollTab';
import { ResistRollTab } from './views/resistRoll/ResistRollTab';
import { DowntimeRollTab } from './views/downtimeRoll/DowntimeRollTab';
import { FortuneRollTab } from './views/fortuneRoll/FortuneRollTab';

const RollPairs = {
  'Action Roll': <ActionRollTab />,
  'Resistance Roll': <ResistRollTab />,
  'Downtime Roll': <DowntimeRollTab />,
  'Fortune Roll': <FortuneRollTab />,
};

export function RollTabs() {
  return (
    <Tabs isFitted w="100%" defaultIndex={0} isLazy>
      <TabList border={0}>
        {Object.keys(RollPairs).map((rollName) => (
          <Tab
            key={rollName}
            _selected={{
              color: 'accent',
              fontWeight: 500,
              borderColor: 'accent',
            }}
          >
            <Heading size="sm">{rollName}</Heading>
          </Tab>
        ))}
      </TabList>

      <TabPanels pt={1}>
        {Object.keys(RollPairs).map((rollName) => (
          <TabPanel p={0} key={rollName}>
            {RollPairs[rollName]}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}
