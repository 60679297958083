import React, { useState } from 'react';
import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';

import { Panel } from '../../components/Panel';
import { Stress } from './Stress';
import { Harm } from './Harm';
import { Stats } from './Stats';
import { Load } from './Load';
import { SpecialAbilities } from './SpecialAbilities';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

function EditableCharacterName() {
  const characterName = useStoreState(CharacterStore, (s) => s.name);
  const [tempName, setTempName] = useState(null);

  return (
    <Editable
      value={tempName != null ? tempName : characterName}
      placeholder={characterName}
      onChange={(name) => {
        setTempName(name);
      }}
      onSubmit={() => {
        if (tempName) {
          CharacterStore.update((s) => {
            s.name = tempName;
          });
        }
        setTempName(null);
      }}
    >
      <EditablePreview color="accent" />
      <EditableInput />
    </Editable>
  );
}

function CharacterPanel() {
  return (
    <>
      <Flex width="360px" justify="center">
        <Heading size="md" m={3}>
          <EditableCharacterName />
        </Heading>
      </Flex>
      <Stack spacing={4}>
        <Stress />
        <Harm />
        <Box>
          <Stats />
        </Box>
        <Load />
      </Stack>
    </>
  );
}

export function Character() {
  return (
    <Stack maxHeight="100%" w="min-content" pb={4} overflow="auto">
      <Panel>
        <CharacterPanel />
      </Panel>
      <Panel minHeight="150px" overflow="auto">
        <SpecialAbilities />
      </Panel>
    </Stack>
  );
}
