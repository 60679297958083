import React from 'react';
import PropTypes from 'prop-types';

import { formatDice, formatRoll } from './utils';

export function FortuneRollMessageContent(props) {
  const {
    characterName,
    fortune,
    advantage,
    disadvantage,
    roll,
    caption,
  } = props;

  const rollMessage = ` rolled ${formatDice(fortune, advantage, disadvantage)}`;

  return (
    <>
      <div>
        <span>{characterName}</span>
        {rollMessage}
      </div>
      <div></div>
      <div>
        <p>{caption}</p>
        {formatRoll(roll)}
      </div>
    </>
  );
}

FortuneRollMessageContent.propTypes = {
  characterName: PropTypes.string,
  fortune: PropTypes.number,
  advantage: PropTypes.number,
  disadvantage: PropTypes.number,
  roll: PropTypes.string,
  caption: PropTypes.string,
};
