export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getChannel() {
  return decodeURI(window.location.pathname.slice(1));
}

export function getCharacter() {
  return decodeURI(window.location.search.slice(1));
}

function getUrl(channelName, characterName, anchor) {
  var path = '/';
  if (channelName) {
    path += channelName;
  }
  if (characterName) {
    path += `?${characterName}`;
  }
  if (anchor) {
    path += `#${anchor}`;
  }
  return path;
}

export function setChannelCharacter(
  channelName = null,
  characterName = null,
  anchor = null
) {
  window.location = getUrl(channelName, characterName, anchor);
}

export function updateChannelCharacter(
  channelName,
  characterName,
  anchor = null
) {
  if (!channelName) {
    channelName = getChannel();
  }
  if (!characterName) {
    characterName = getCharacter();
  }
  if (!anchor) {
    anchor = window.location.hash ? window.location.hash.slice(1) : '';
  }
  window.history.replaceState(
    null,
    document.title,
    getUrl(channelName, characterName, anchor)
  );
}
