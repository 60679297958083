import React from 'react';
import { Icon } from '@chakra-ui/react';
import { FaCircle, FaRegCircle } from 'react-icons/fa';

export function Pips(props) {
  const { value } = props;
  return (
    <>
      {value > 0 ? (
        <>
          {Array.from({ length: value }, (_, i) => (
            <Icon as={FaCircle} key={i} boxSize="8px" />
          ))}
        </>
      ) : (
        <Icon as={FaRegCircle} boxSize="8px" />
      )}
    </>
  );
}
