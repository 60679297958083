import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Link,
  Spacer,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import { InfoPopover, PopoverSpan } from '../../components/InfoPopover';

import { capitalize } from '../../functions/utils';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';
import { GameplayStore } from '../../stores/gameplayStore';

function getSpecialAbility(specialAbility) {
  if (!specialAbility) {
    return;
  }
  if (
    CharacterStore.currentState.customAbilities.hasOwnProperty(specialAbility)
  ) {
    return CharacterStore.currentState.customAbilities[specialAbility];
  }
  if (
    GameplayStore.currentState['special abilities'].hasOwnProperty(
      specialAbility
    )
  ) {
    return GameplayStore.currentState['special abilities'][specialAbility];
  }
  return;
}

function SpecialAbility(props) {
  const ability = props.value;
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.100');

  const specialAbility = getSpecialAbility(ability);
  if (!specialAbility) {
    return null;
  }

  return (
    <Box p={1} rounded="lg" bg={bgColor}>
      <Accordion allowToggle>
        <AccordionItem border={0}>
          <Stack direction="column">
            <AccordionButton roundedTop="md">
              <Heading mx={1} size="sm" w="fit-content" textAlign="left">
                <InfoPopover source={specialAbility.description || ''}>
                  <Box>
                    <PopoverSpan>{capitalize(ability)}</PopoverSpan>
                  </Box>
                </InfoPopover>
              </Heading>
              <Spacer />
              <AccordionIcon />
            </AccordionButton>
            <Box
              textAlign="left"
              paddingLeft={2}
              mt={0}
              pb={1}
              style={{ fontSize: '.9rem' }}
            >
              <ReactMarkdown
                source={specialAbility.short || ''}
                escapeHtml={false}
              />
            </Box>
          </Stack>
          <AccordionPanel pb={1} pt={0}>
            <Box textAlign="left" style={{ fontSize: '.9rem' }}>
              <ReactMarkdown
                source={specialAbility.description || ''}
                escapeHtml={false}
              />
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}

function addSpecialAbility(name, shortDescription, fullDescription) {
  const specialAbility = {
    [name]: { short: shortDescription, description: fullDescription },
  };
  CharacterStore.update((s) => {
    s.customAbilities = { ...s.customAbilities, ...specialAbility };
    s.abilities = s.abilities ? s.abilities.concat(name) : [name];
  });
}

function AddSpecialAbilityModal(props) {
  return (
    <SpecialAbilityModal
      name="Add Special Ability"
      onSubmit={addSpecialAbility}
      {...props}
    >
      <Button
        type="submit"
        colorScheme="teal"
        onClick={() => {
          props.onClose();
        }}
      >
        Add Special Ability
      </Button>
    </SpecialAbilityModal>
  );
}

function EditSpecialAbilityModal(props) {
  return (
    <SpecialAbilityModal
      name="Edit Special Ability"
      onSubmit={addSpecialAbility}
      {...props}
    >
      <Button
        type="submit"
        colorScheme="red"
        onClick={() => {
          props.onClose();
        }}
      >
        Remove
      </Button>
      <Button
        type="submit"
        colorScheme="teal"
        onClick={() => {
          props.onClose();
        }}
      >
        Save Changes
      </Button>
    </SpecialAbilityModal>
  );
}

function SpecialAbilityModal({
  name,
  ability,
  isOpen,
  onClose,
  onSubmit,
  children,
}) {
  const [tempName, setTempName] = useState(ability?.name ?? '');
  const [tempShort, setTempShort] = useState(ability?.short ?? '');
  const [tempFull, setTempFull] = useState(ability?.description ?? '');
  const cancelRef = React.useRef();

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(tempName, tempShort, tempFull);
    setTempName('');
    setTempShort('');
    setTempFull('');
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" pb={0}>
            {name}
          </AlertDialogHeader>

          <form onSubmit={handleSubmit}>
            <AlertDialogBody>
              <Stack direction="column">
                <Input
                  type="text"
                  name="name"
                  rounded="lg"
                  placeholder="Special Ability Name"
                  value={tempName}
                  onChange={(e) => setTempName(e.target.value)}
                />
                <Text fontSize="sm">
                  You can use{' '}
                  <Link
                    href="https://www.markdownguide.org/cheat-sheet/"
                    color="teal.500"
                    isExternal
                  >
                    markdown
                  </Link>{' '}
                  for descriptions
                </Text>
                <Textarea
                  type="text"
                  name="short description"
                  rounded="lg"
                  placeholder="Short ability description"
                  resize="none"
                  value={tempShort}
                  onChange={(e) => setTempShort(e.target.value)}
                />
                <Textarea
                  type="text"
                  name="full description"
                  rounded="lg"
                  placeholder="Full ability description"
                  value={tempFull}
                  onChange={(e) => setTempFull(e.target.value)}
                />
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                {children}
              </HStack>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function SpecialAbilitiesButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        size="sm"
        leftIcon={<AddIcon />}
        colorScheme="teal"
        onClick={onOpen}
      >
        Special Ability
      </Button>

      <AddSpecialAbilityModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export function SpecialAbilities() {
  const specialAbilities = useStoreState(CharacterStore, (s) => s?.abilities);

  return (
    <Stack p={1}>
      {specialAbilities &&
        specialAbilities.map((ability, i) => (
          <SpecialAbility key={`${ability}-${i}`} value={ability} />
        ))}
    </Stack>
  );
}
