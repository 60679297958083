import React, { useEffect } from 'react';
import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  VStack,
  useBreakpoint,
  useColorModeValue,
  ChakraProvider,
} from '@chakra-ui/react';
import { customTheme } from './theme';
import { UIControls } from './components/UIControls';
import { RollTabs } from './RollTabs';
import { Panel } from './components/Panel';
import { Character } from './views/character/Character';
import { MessageBox } from './Messages';
import { Channel } from './views/Channel';

import { getChannel, getCharacter } from './functions/utils';

import { useStoreState } from 'pullstate';
import {
  CharacterStore,
  subscribeChannelCharacter,
} from './stores/characterStore';
import { GameplayStore, getGameplayStore } from './stores/gameplayStore';
import { subscribeChannelMessages } from './stores/messageStore';

function LoadingScreen({ gameplayLoaded, characterLoaded }) {
  return (
    <Center h="80%">
      <VStack>
        <HStack>
          <Spinner size="lg" color="teal.500" mr={1} />
          <Heading size="md" as="i" color="teal.500">
            Loading
          </Heading>
        </HStack>
        {gameplayLoaded && (
          <Text as="i" color="teal.500">
            Gameplay Loaded
          </Text>
        )}
        {characterLoaded && (
          <Text as="i" color="teal.500">
            Character Loaded
          </Text>
        )}
      </VStack>
    </Center>
  );
}

function GameplayView(props) {
  const channel = getChannel();
  const breakpoint = useBreakpoint();
  const leftBgColor = useColorModeValue('gray.100', 'teal.700');
  const rightBgColor = useColorModeValue('gray.400', 'teal.700');

  return (
    <>
      <HStack mx={5}>
        <Box _hover={{ color: 'accent' }}>
          <Heading size="md">Forged in the Dark Roller</Heading>
        </Box>
        <Flex flex={1}>
          {process.env.NODE_ENV === 'development' && breakpoint}
        </Flex>
        <UIControls />
      </HStack>
      <Stack direction="row" spacing={1} minH="0" flex="1">
        <VStack
          id="leftColumn"
          bg={leftBgColor}
          width="525px"
          p={2}
          pb={4}
          rounded="lg"
          overflowX="hidden"
        >
          <RollTabs />
          <Panel minHeight="300px" overflow="auto" w="100%">
            <MessageBox />
          </Panel>
        </VStack>
        <Box id="rightColumn" bg={rightBgColor} p={2} rounded="lg">
          <Flex justify="center">
            <Heading size="sm">{channel?.toLocaleUpperCase()}</Heading>
          </Flex>
          <Character />
        </Box>
      </Stack>
    </>
  );
}

function MainView(props) {
  const { channel: channelName, character: characterName } = props;
  const gameplay = useStoreState(GameplayStore);
  const character = useStoreState(CharacterStore);
  const isLoaded = gameplay && character;

  useEffect(() => {
    getGameplayStore(channelName);
    subscribeChannelMessages(channelName);
    return subscribeChannelCharacter(channelName, characterName);
  }, [channelName, characterName]);

  return (
    <Center>
      <Box
        className="App"
        display="flex"
        flexDir="column"
        margin="0px"
        h="100vh"
        w="fit-content"
      >
        {isLoaded ? (
          <GameplayView />
        ) : (
          <LoadingScreen
            gameplayLoaded={gameplay}
            characterLoaded={character}
          />
        )}
      </Box>
    </Center>
  );
}

function App() {
  const channel = getChannel();
  const character = getCharacter();

  return (
    <ChakraProvider theme={customTheme}>
      {channel && character ? (
        <MainView channel={channel} character={character} />
      ) : (
        <Channel channel={channel} character={character} />
      )}
    </ChakraProvider>
  );
}

export default App;
