import React from 'react';
import { Box, Code } from '@chakra-ui/react';

export function formatDice(base, add = 0, minus = 0) {
  if (!base) {
    return null;
  }
  var rollText = `${base}d`;
  if (add > 0) {
    rollText += ` +${add}d`;
  }
  if (minus > 0) {
    rollText += ` -${minus}d`;
  }
  return `(${rollText})`;
}

export function formatStress(total, add = 0, minus = 0, base = null) {
  var stressText = `stress: ${base !== null ? base : total - add + minus}`;
  if (add > 0) {
    stressText += ` + ${add}`;
  }
  if (minus > 0) {
    stressText += ` - ${minus}`;
  }
  stressText += ` → ${total}`;
  return (
    <Box d="block">
      <Code style={{ fontSize: '0.85rem' }}>{stressText}</Code>
    </Box>
  );
}

export function formatRoll(roll) {
  return roll ? (
    <Box d="block">
      <Code style={{ fontSize: '0.85rem' }}>{roll}</Code>
    </Box>
  ) : (
    <></>
  );
}
