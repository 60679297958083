import React from 'react';
import { Flex, Heading, HStack } from '@chakra-ui/react';

import { StatSlider } from '../../components/CheckboxSlider';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

export const xpColor = 'blue';

export function Stress() {
  const value = useStoreState(CharacterStore, (s) => s.stress);

  function handleChange(value) {
    CharacterStore.update((s) => {
      s.stress = value;
    });
  }

  return (
    <Flex mx={1} align="center" justify="center">
      <HStack marginRight={3}>
        <StatSlider
          flex="1"
          value={value}
          onChange={handleChange}
          min={0}
          max={4}
        />
        <StatSlider
          flex="1"
          value={value}
          onChange={handleChange}
          colorScheme="low"
          min={4}
          max={6}
        />
        <StatSlider
          flex="1"
          value={value}
          onChange={handleChange}
          colorScheme="medium"
          min={6}
          max={7}
        />
        <StatSlider
          flex="1"
          value={value}
          onChange={handleChange}
          colorScheme="high"
          min={7}
          max={8}
        />
        <StatSlider
          flex="1"
          value={value}
          onChange={handleChange}
          colorScheme="red"
          min={8}
          max={9}
        />
      </HStack>
      <Flex mx={1}>
        <Heading size="sm">Stress</Heading>
      </Flex>
    </Flex>
  );
}
