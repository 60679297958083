import React from 'react';
import { Button, Flex, Input } from '@chakra-ui/react';

import { viceRoll } from '../../functions/rolls';

import { useStoreState } from 'pullstate';
import { RollStore, resetRollStore } from '../../stores/rollStore';

function roll() {
  viceRoll();
  resetRollStore();
}

export function RollButton() {
  const activity = useStoreState(RollStore, (s) => s.activity);
  const caption = useStoreState(RollStore, (s) => s.caption);

  function handleSubmit(e) {
    e.preventDefault();
    if (!activity) {
      return;
    }
    roll();
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Flex justify="center">
        <Input
          m={1}
          type="text"
          variant="filled"
          placeholder="Caption roll"
          rounded="lg"
          value={caption}
          onChange={(e) => {
            RollStore.update((s) => {
              s.caption = e.target.value;
            });
          }}
        />
      </Flex>
      <Flex justify="center">
        <Button
          type="submit"
          isDisabled={!activity || activity !== 'vice'}
          colorScheme="teal"
        >
          Roll
        </Button>
      </Flex>
    </form>
  );
}
