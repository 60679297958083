import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Heading, SimpleGrid } from '@chakra-ui/react';

import { InfoPopoverButton, PopoverSpan } from '../../components/InfoPopover';
import { Pips } from '../../components/Pips';
import { capitalize } from '../../functions/utils';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';
import { GameplayStore } from '../../stores/gameplayStore';
import { RollStore } from '../../stores/rollStore';

function AttributeButton(props) {
  const { value: attributeName, isChecked, isDisabled, ...rest } = props;
  const attributes = useStoreState(CharacterStore, (s) => s?.attributes);
  const resist = useStoreState(
    GameplayStore,
    (s) => s.attributes[attributeName].resist
  );
  const pips = attributes && <Pips value={attributes[attributeName]} />;

  return (
    <InfoPopoverButton
      value={attributeName}
      w="130px"
      m="3px"
      colorScheme={isChecked ? 'medium' : 'gray'}
      _checked={{ bg: 'medium.500' }}
      _hover={{ bg: 'medium.400' }}
      aria-checked={isChecked}
      isDisabled={isDisabled}
      role="radio"
      onClick={() => {
        RollStore.update((s) => {
          s.attribute = attributeName;
        });
      }}
      source={resist}
      {...rest}
    >
      <span>
        <PopoverSpan>
          <span className="action">{capitalize(attributeName)}</span>
        </PopoverSpan>
        <span className="actionRating">
          &nbsp;({attributes ? attributes[attributeName] : 0}d)
          <br />
          {pips}
        </span>
      </span>
    </InfoPopoverButton>
  );
}

export function AttributeSelect() {
  const attributes = useStoreState(GameplayStore, (s) => s.attributes);
  const resist = useStoreState(GameplayStore, (s) => s.resist.description);
  const attribute = useStoreState(RollStore, (s) => s.attribute);

  return (
    <Box>
      <Heading size="md" flex={1} m={3}>
        Resist
      </Heading>
      <Box mx={3} mb={3}>
        <ReactMarkdown source={resist} escapeHtml={false} />
      </Box>

      <SimpleGrid columns={3} spacing={3}>
        {Object.keys(attributes).map((attributeName) => (
          <div align="center" key={attributeName}>
            <AttributeButton
              value={attributeName}
              attributeName={attributeName}
              isChecked={attributeName === attribute}
            />
          </div>
        ))}
      </SimpleGrid>
    </Box>
  );
}
