import { DiceRoll } from 'rpg-dice-roller';

import { CharacterStore } from '../stores/characterStore';
import { RollStore } from '../stores/rollStore';
import { addMessage } from '../stores/messageStore';

function rollXd6(x = 0, bonus = 0, penalty = 0) {
  var keepModifier = 'h'; // h = highest
  const dTotal = x + bonus - penalty;
  if (dTotal <= 0) {
    keepModifier = 'l'; // l = lowest
    x = 2;
  } else {
    x = `${x}`;
    if (bonus > 0) {
      x = `${x}+${bonus}`;
    }
    if (penalty > 0) {
      x = `${x}-${penalty}`;
    }
    if (bonus > 0 || penalty > 0) {
      x = `(${x})`;
    }
  }

  return new DiceRoll(`${x}d6k${keepModifier}1`);
}

function getResult(rolls) {
  var highestRoll = rolls.value;

  if (highestRoll === 6) {
    for (let roll of rolls.rolls) {
      if (roll.value === 6 && roll.useInTotal === false) {
        return 'crit';
      }
    }
  }

  if (highestRoll === 6) {
    return 'hit';
  }
  if (highestRoll > 3) {
    return 'mixed';
  }
  return 'miss';
}

export function actionRoll() {
  const { action, bonus, advantage, disadvantage } = {
    action: RollStore.currentState.action,
    bonus: RollStore.currentState.bonus,
    advantage: RollStore.currentState.advantage,
    disadvantage: RollStore.currentState.disadvantage,
  };

  const rating = CharacterStore.currentState.actions[action] || 0;

  const roll = rollXd6(rating, bonus + advantage, disadvantage);
  const result = getResult(roll.rolls[0]);

  const rollMessage = {
    type: 'actionRoll',
    characterName: CharacterStore.currentState.name,
    actionName: action,
    actionRating: rating,
    bonus: bonus,
    advantage: advantage,
    disadvantage: disadvantage,
    position: RollStore.currentState.position,
    effect: RollStore.currentState.effect,
    outcome: result,
    roll: roll.output,
    caption: RollStore.currentState.caption || null,
  };

  addMessage(rollMessage);
}

export function resistRoll() {
  const attribute = RollStore.currentState.attribute;
  const { rating, advantage, disadvantage } = {
    rating: CharacterStore.currentState.attributes[attribute],
    advantage: RollStore.currentState.advantage,
    disadvantage: RollStore.currentState.disadvantage,
  };

  const roll = rollXd6(rating, advantage, disadvantage);
  const result = getResult(roll.rolls[0]);
  var addStress = 6 - (result === 'crit' ? 7 : roll.total);

  const rollMessage = {
    type: 'resistRoll',
    characterName: CharacterStore.currentState.name,
    attributeName: attribute,
    attributeRating: rating,
    advantage: advantage,
    disadvantage: disadvantage,
    outcome: result,
    roll: roll.output,
    addStress: addStress,
    caption: RollStore.currentState.caption,
  };

  CharacterStore.update((s) => {
    s.stress = Math.min(Math.max(0, s.stress + addStress), 9);
  });

  rollMessage.totalStress = CharacterStore.currentState.stress;

  addMessage(rollMessage);
}

export function viceRoll() {
  const stress = CharacterStore.currentState.stress;
  const rating = CharacterStore.currentState.vice || 0;
  const advantage = RollStore.currentState.advantage;
  const disadvantage = RollStore.currentState.disadvantage;

  const roll = rollXd6(rating, advantage, disadvantage);
  const removeStress = roll.total;
  const result = removeStress > stress ? 'overindulged' : 'hit';

  const rollMessage = {
    type: 'viceRoll',
    characterName: CharacterStore.currentState.name,
    rating: rating,
    advantage: advantage,
    disadvantage: disadvantage,
    outcome: result,
    roll: roll.output,
    startStress: stress,
    removeStress: removeStress,
    caption: RollStore.currentState.caption,
  };

  CharacterStore.update((s) => {
    s.stress = Math.min(Math.max(0, s.stress - removeStress), 9);
  });

  rollMessage.totalStress = CharacterStore.currentState.stress;

  addMessage(rollMessage);
}

// TODO: disadvantage
export function fortuneRoll() {
  const fortune = RollStore.currentState.fortune;
  const advantage = RollStore.currentState.advantage;
  const disadvantage = RollStore.currentState.disadvantage;

  const roll = rollXd6(fortune, advantage, disadvantage);
  const result = getResult(roll.rolls[0]);

  const rollMessage = {
    type: 'fortuneRoll',
    characterName: CharacterStore.currentState.name,
    fortune: fortune,
    advantage: advantage,
    disadvantage: disadvantage,
    outcome: result,
    roll: roll.output,
    caption: RollStore.currentState.caption || null,
  };

  addMessage(rollMessage);
}
