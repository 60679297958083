import React from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Button,
  Portal,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';

import { useStoreState } from 'pullstate';
import { SettingsStore } from '../stores/settingsStore';

export function InfoPopover(props) {
  const { children, source, ...rest } = props;
  const showInfoMode = useStoreState(SettingsStore, (s) => s.showInfoMode);

  return source ? (
    <>
      <Popover trigger="hover" placement="top" {...rest}>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>{children}</PopoverTrigger>
            {isOpen && (
              <Portal>
                <PopoverContent zIndex={4} d={showInfoMode ? 'block' : 'none'}>
                  <PopoverArrow />
                  <PopoverBody>
                    <ReactMarkdown source={source} escapeHtml={false} />
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            )}
          </>
        )}
      </Popover>
    </>
  ) : (
    <>{children}</>
  );
}

export function InfoPopoverButton(props) {
  const { source, onClick, children, ...rest } = props;
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Button
      onClick={() => {
        onClick();
        onClose();
      }}
      {...rest}
    >
      <InfoPopover
        source={source}
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        children={children}
      />
    </Button>
  );
}

export function PopoverSpan(props) {
  const showInfoMode = useStoreState(SettingsStore, (s) => s.showInfoMode);

  return (
    <span style={{ borderBottom: showInfoMode ? '1px dotted gray' : '' }}>
      {props.children}
    </span>
  );
}
