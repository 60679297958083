import { Store } from 'pullstate';
import { db } from './firebase';

import { GameplayStore } from '../stores/gameplayStore';

import { updateChannelCharacter } from '../functions/utils';

function calculateAttributes(actions) {
  var attributes = {};
  for (const attr in GameplayStore.currentState.attributes) {
    attributes[attr] = 0;
    for (const action of GameplayStore.currentState.attributes[attr].actions) {
      if (actions[action]) {
        attributes[attr] += 1;
      }
    }
  }
  return attributes;
}

function getInitialCharacterState(characterName) {
  return {
    name: characterName,
    playbook: null,
    stress: 0,
    actions: {
      attune: 0,
      command: 0,
      consort: 0,
      finesse: 0,
      hunt: 0,
      prowl: 0,
      skirmish: 0,
      study: 0,
      survey: 0,
      sway: 0,
      tinker: 0,
      wreck: 0,
    },
    attributes: {
      insight: 0,
      prowess: 0,
      resolve: 0,
    },
    vice: 0,
    abilities: [],
    xp: {
      insight: 0,
      prowess: 0,
      resolve: 0,
      playbook: 0,
      unused: 0,
    },
  };
}

export const CharacterStore = new Store();

export function subscribeChannelCharacter(channelName, characterName) {
  const subscriptions = [];

  if (!channelName) {
    return;
  }

  // update character ref
  // query for a character of that name
  db.ref(channelName)
    .child('characters')
    .orderByChild('name')
    .equalTo(characterName)
    .once('value')
    .then(function (snapshot) {
      let characterRef = null;

      if (snapshot.exists()) {
        console.log('Character exists');
        snapshot.forEach(function (childSnapshot) {
          characterRef = childSnapshot.ref;
        });
      } else {
        console.log('Creating new character');
        // if character with name does not exist, create a new one
        characterRef = db.ref(channelName).child('characters').push().ref;
        characterRef.set(getInitialCharacterState(characterName));
      }
      /*// purely for logging:
      characterRef.once('value').then(function (snapshot) {
        console.log(snapshot.val());
      });*/

      // subscribe to firebase store
      characterRef.on('value', function (snapshot) {
        if (snapshot.exists()) {
          CharacterStore.replace(snapshot.val());
          //console.log('firebase update');
          //console.log(snapshot.val());
        } else {
          // character has been deleted, reset data
          characterRef.set(
            getInitialCharacterState(CharacterStore.currentState.name)
          );
        }

        subscriptions.push(
          CharacterStore.createReaction(
            (s) => s,
            (s, draft) => {
              if (!characterRef) {
                return;
              }
              draft.attributes = calculateAttributes(draft.actions);
              var rating = 10;
              Object.values(draft.attributes).forEach((value) => {
                if (value < rating) {
                  rating = value;
                }
              });
              draft.vice = rating;
              characterRef.update(draft);
            }
          )
        );

        subscriptions.push(
          CharacterStore.createReaction(
            (s) => s.name,
            (name) => {
              updateChannelCharacter(null, name);
            }
          )
        );
      });

      subscriptions.push(() => characterRef.off());
    });

  return () => subscriptions.forEach((unsub) => unsub());
}
