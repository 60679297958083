import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

export function Panel(props) {
  const bgColor = useColorModeValue('white', 'gray.700');

  return (
    <Box
      className="panel"
      flex={1}
      rounded="lg"
      bg={bgColor}
      p={1}
      m={1}
      {...props}
    />
  );
}
