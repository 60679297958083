import React from 'react';
import PropTypes from 'prop-types';

import { formatDice, formatStress, formatRoll } from './utils';
import { InfoPopover, PopoverSpan } from '../InfoPopover';

import { GameplayStore } from '../../stores/gameplayStore';

// Test Character cleared 4 stress by ingulging in vice with PROWESS (2d)
function ViceRollMessageText(props) {
  const {
    characterName,
    removeStress,
    rating,
    advantage,
    disadvantage,
    totalStress,
  } = props;

  return (
    <div>
      <span>{characterName}</span>
      {` cleared ${removeStress} stress by Indulging in Vice ${formatDice(
        rating,
        advantage,
        disadvantage
      )} – total stress is now ${totalStress}`}
    </div>
  );
}

// Test Character overindulged in vice with PROWESS (2d)
function OverindulgeViceRollMessageText(props) {
  const { characterName, rating, advantage, disadvantage, totalStress } = props;

  return (
    <div>
      <span>{characterName}</span>{' '}
      <InfoPopover
        source={
          GameplayStore.currentState.downtime.activities['Indulge Vice']
            .overindulgence
        }
      >
        <span>
          <PopoverSpan>Overindulged</PopoverSpan>
        </span>
      </InfoPopover>
      {` in Vice ${formatDice(
        rating,
        advantage,
        disadvantage
      )} – total stress is now ${totalStress}`}
    </div>
  );
}

export function ViceRollMessageContent(props) {
  const {
    outcome,
    startStress,
    removeStress,
    totalStress,
    caption,
    roll,
  } = props;

  var messageText;
  if (outcome === 'overindulged') {
    messageText = OverindulgeViceRollMessageText(props);
  } else {
    messageText = ViceRollMessageText(props);
  }

  return (
    <div>
      <div>{messageText}</div>
      <div>
        <p>{caption}</p>
        {formatStress(totalStress, 0, removeStress, startStress)}
        {formatRoll(roll)}
      </div>
    </div>
  );
}

ViceRollMessageContent.propTypes = {
  characterName: PropTypes.string,
  removeStress: PropTypes.number,
  rating: PropTypes.string,
  totalStress: PropTypes.string,
  outcome: PropTypes.string,
  startStress: PropTypes.string,
  caption: PropTypes.string,
  roll: PropTypes.string,
};
