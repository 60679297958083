import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Checkbox, Flex, Heading, Text } from '@chakra-ui/react';

import { useStoreState } from 'pullstate';
import { GameplayStore } from '../../stores/gameplayStore';
import { RollStore } from '../../stores/rollStore';

function updateBonus(bonus, isChecked) {
  RollStore.update((s) => {
    s.bonuses[bonus] = isChecked;
  });
}

function BonusCheckbox(props) {
  const { value: bonusType, text } = props;
  const bonus = useStoreState(RollStore, (s) => s.bonuses[bonusType]);

  return (
    <Box>
      <Checkbox
        colorScheme="orange"
        isChecked={bonus}
        onChange={(e) => updateBonus(bonusType, e.target.checked)}
      >
        <ReactMarkdown p={0} source={text} escapeHtml={false} />
      </Checkbox>
    </Box>
  );
}

export function Bonuses() {
  const bonusTypes = useStoreState(GameplayStore, (s) => s.bonus);
  const bonus = useStoreState(RollStore, (s) => s.bonus);

  return (
    <Box>
      <Heading size="md" flex={1} m={3}>
        Bonus Dice
      </Heading>
      <Flex align="center">
        <Text fontSize="lg" p={2} mx={3} my={1}>
          +{bonus}d
        </Text>
        <Box>
          {Object.keys(bonusTypes).map((bonusType) => (
            <BonusCheckbox
              key={bonusType}
              value={bonusType}
              text={bonusTypes[bonusType].text}
            />
          ))}
        </Box>
      </Flex>
    </Box>
  );
}
