import React from 'react';
import { Link, Text } from '@chakra-ui/react';

export function License(props) {
  return (
    <Text>
      This app is based on{' '}
      <Link color="teal.500" href="http://www.bladesinthedark.com/">
        Blades in the Dark
      </Link>
      , product of One Seven Design, developed and authored by John Harper, and
      licensed for use under the{' '}
      <Link color="teal.500" href="http://creativecommons.org/licenses/by/3.0/">
        Creative Commons Attribution 3.0 Unported license
      </Link>
      .
    </Text>
  );
}
