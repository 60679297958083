import React from 'react';
import PropTypes from 'prop-types';

import { formatDice, formatStress, formatRoll } from './utils';

// Test Character took 4 stress to resist with PROWESS (2d)
function ResistRollMessageText({
  characterName,
  addStress,
  attributeName,
  attributeRating,
  advantage,
  disadvantage,
  totalStress,
}) {
  return (
    <div>
      <span>{characterName}</span>
      {` took ${addStress} stress to resist with `}
      <span>{attributeName?.toUpperCase()}</span>
      {` ${formatDice(
        attributeRating,
        advantage,
        disadvantage
      )} – total stress is now ${totalStress}`}
    </div>
  );
}

// Test Character resisted with PROWESS (2d) and cleared 1 stress
function CritResistRollMessageText({
  characterName,
  attributeName,
  attributeRating,
  totalStress,
}) {
  return (
    <div>
      <span>{characterName}</span>
      {` resisted with `}
      <span>{attributeName?.toUpperCase()}</span>
      {` ${formatDice(
        attributeRating
      )} and cleared 1 stress – total stress is now ${totalStress}`}
    </div>
  );
}

export function ResistRollMessageContent(props) {
  const { addStress, totalStress, caption, roll } = props;
  var stress = '';

  var messageText;
  if (addStress < 0) {
    messageText = CritResistRollMessageText(props);
    stress = formatStress(totalStress, 0, -addStress);
  } else {
    messageText = ResistRollMessageText(props);
    stress = formatStress(totalStress, addStress);
  }

  return (
    <>
      <div>{messageText}</div>
      <div>
        <p>{caption}</p>
        {stress}
        {formatRoll(roll)}
      </div>
    </>
  );
}

ResistRollMessageContent.propTypes = {
  characterName: PropTypes.string,
  addStress: PropTypes.number,
  attributeName: PropTypes.string,
  attributeRating: PropTypes.number,
  advantage: PropTypes.number,
  disadvantage: PropTypes.number,
  totalStress: PropTypes.number,
  roll: PropTypes.string,
  caption: PropTypes.string,
};
