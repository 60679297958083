import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  CloseButton,
  HStack,
  Input,
  Skeleton,
  useColorModeValue,
} from '@chakra-ui/react';

import { ActionRollMessageContent } from './components/messages/ActionRollMessage';
import { FortuneRollMessageContent } from './components/messages/FortuneRollMessage';
import { ResistRollMessageContent } from './components/messages/ResistRollMessage';
import { SessionNoteMessageContent } from './components/messages/SessionNoteMessage';
import { ViceRollMessageContent } from './components/messages/ViceRollMessage';

import { useStoreState } from 'pullstate';
import { MessageStore, addMessage, removeMessage } from './stores/messageStore';
import { CharacterStore } from './stores/characterStore';

function MessageContent(message) {
  if (!message) {
    return;
  }
  if (message.type === 'actionRoll') {
    return ActionRollMessageContent(message);
  } else if (message.type === 'resistRoll') {
    return ResistRollMessageContent(message);
  } else if (message.type === 'fortuneRoll') {
    return FortuneRollMessageContent(message);
  } else if (message.type === 'sessionNote') {
    return SessionNoteMessageContent(message);
  } else if (message.type === 'viceRoll') {
    return ViceRollMessageContent(message);
  } else {
    return <ReactMarkdown source={message} />;
  }
}

export function Message(props) {
  const { id, message, ...rest } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.50');
  const content = MessageContent(message);
  if (!content) {
    return null;
  }

  return (
    <>
      <Box bg={bgColor} rounded="lg" m={2} p={1} {...rest}>
        <CloseButton float="right" size="sm" onClick={() => setIsOpen(true)} />
        {content}
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" pb={0}>
              Remove Message
            </AlertDialogHeader>

            <AlertDialogBody>
              <Box bg={bgColor} rounded="lg" m={2} p={1} {...rest}>
                {MessageContent(message)}
              </Box>
              Are you sure you want to remove this message? You can't undo this
              action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  removeMessage(id);
                  onClose();
                }}
                ml={3}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

function MessageForm() {
  const [note, setNote] = useState('');
  const characterName = useStoreState(CharacterStore, (s) => s?.name);
  const isLoaded = characterName !== undefined;

  function handleSubmit(event) {
    event.preventDefault();
    if (!note) {
      return;
    }
    const message = {
      type: 'sessionNote',
      characterName: characterName,
      message: note,
    };
    addMessage(message);
    setNote('');
  }

  return (
    <form onSubmit={handleSubmit}>
      <HStack m={1} p={1}>
        <Input
          type="text"
          variant="filled"
          placeholder="Add session note"
          rounded="lg"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <Button type="submit" colorScheme="teal" isDisabled={!isLoaded}>
          Submit
        </Button>
      </HStack>
    </form>
  );
}

export function MessageBox() {
  const messages = useStoreState(MessageStore, (s) => s?.messages);

  return (
    <Box className="messagebox" flex={1}>
      <MessageForm />
      {messages ? (
        Object.keys(messages)
          .reverse()
          .map((k) => <Message key={k} id={k} message={messages[k]} />)
      ) : (
        <Skeleton rounded="lg" m={2} height="80px" />
      )}
    </Box>
  );
}
