import React from 'react';
import {
  Box,
  Heading,
  InputGroup,
  InputLeftAddon,
  useColorModeValue,
} from '@chakra-ui/react';

import { ControlledInputArray } from '../../components/ControlledInput';
import { InfoPopover, PopoverSpan } from '../../components/InfoPopover';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';
import { GameplayStore } from '../../stores/gameplayStore';

function HarmGroup({ description, label, count, rating }) {
  const harmValues = useStoreState(
    CharacterStore,
    (s) => s.harm?.[label] || []
  );
  const setHarmValues = (values) => {
    CharacterStore.update((s) => {
      s.harm ?? (s.harm = {});
      s.harm[label] = values;
    });
  };
  const inactiveColor = useColorModeValue('gray.100', 'whiteAlpha.400');

  return (
    <InputGroup size="sm">
      <InputLeftAddon
        bg={harmValues.some((x) => x) ? `${rating}.500` : inactiveColor}
        children={
          description ? (
            <InfoPopover source={description}>
              <Box>
                <PopoverSpan>
                  <b>{label || ''}</b>
                </PopoverSpan>
              </Box>
            </InfoPopover>
          ) : (
            label
          )
        }
      />
      <ControlledInputArray
        values={harmValues}
        onChange={setHarmValues}
        max={count}
        fill=""
        debounceMs={300}
      />
    </InputGroup>
  );
}

export function Harm() {
  const harmData = useStoreState(GameplayStore, (s) => s?.harm);

  if (!harmData) {
    return null;
  }

  return (
    <Box margin={1} padding={2}>
      <Heading size="sm">Harm</Heading>
      <Box padding={1}>
        {harmData.order.map((levelName) => {
          const harmLevel = harmData.levels[levelName];
          return (
            <HarmGroup
              key={levelName}
              label={levelName}
              count={harmLevel.count}
              description={harmLevel.description}
              rating={harmLevel.rating}
            />
          );
        })}
      </Box>
    </Box>
  );
}
