import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Center, Flex, IconButton, Text } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';

import { useStoreState } from 'pullstate';
import { RollStore } from '../stores/rollStore';

export function LabelledNumberInput({
  label,
  defaultValue,
  onChange,
  min,
  max,
  prefix,
  postfix,
  fontSize,
  size,
}) {
  const [value, setValue] = useState(defaultValue);

  function handleChange(modifiedValue) {
    setValue(modifiedValue);
    onChange(modifiedValue);
  }

  return (
    <Flex align="center" m="3px 3px 8px">
      <IconButton
        icon={<MinusIcon />}
        onClick={() => handleChange(value - 1)}
        isDisabled={value === min}
        aria-label="subtract"
        size={size}
      />
      <Box w="50x">
        <Center w="50px">
          {prefix}
          {value}
          {postfix}
        </Center>
      </Box>
      <IconButton
        icon={<AddIcon />}
        colorScheme="teal"
        onClick={() => handleChange(value + 1)}
        isDisabled={max && value === max}
        aria-label="add"
        size={size}
      />
      <Box mx={5}>
        <Text fontSize={fontSize}>{label}</Text>
      </Box>
    </Flex>
  );
}

LabelledNumberInput.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
};

LabelledNumberInput.defaultProps = {
  defaultValue: 1,
  min: 0,
  max: null,
  prefix: '',
  size: 'sm',
};

export function LabelledDicePool({ attr, ...rest }) {
  return (
    <LabelledNumberInput
      onChange={(value) => {
        RollStore.update((s) => {
          s[attr] = value;
        });
      }}
      defaultValue={useStoreState(RollStore, (s) => s[attr])}
      postfix={'d'}
      {...rest}
    />
  );
}
